<template>
	<nav class="navbar header_new navbar-expand-lg">
		<div class="container-fluid">
			<a class="navbar-brand" href="#"><img src="/images/v_logo.png"></a>
			<button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#main_nav"
				aria-expanded="false" aria-label="Toggle navigation">
				<span class="navbar-toggler-icon"></span>
			</button>
			<div class="collapse navbar-collapse" id="main_nav">
				<ul class="navbar-nav">
					<li class="nav-item">
						<router-link class="nav-link position-relative" target="_blank" :to="{ name: 'Inbox'}"> Fax 
							<span class="header_nbr_count d-flex justify-content-center align-items-center" v-if="this.inboxCount > 0">{{ this.inboxCount }}</span>
						</router-link>
					</li>
					<li class="nav-item">
						<router-link class="nav-link" target="_blank" :to="{ name: 'InvoiceMasterDashboard'}"> Billing</router-link>
					</li>
					<li class="nav-item">
						<router-link class="nav-link position-relative" target="_blank" :to="{ name: 'AllPatientActiveTaskList'}"> Tasks <span class="header_nbr_count d-flex justify-content-center align-items-center" v-if="this.taskCount > 0">{{ this.taskCount }}</span></router-link>
					</li>
					<li class="nav-item">
						<router-link class="nav-link" target="_blank" :to="{ name: 'AllConsultationList'}"> Consultation </router-link>
					</li>
					<li class="nav-item">
						<router-link class="nav-link" target="_blank" :to="{ name: 'AllLabList'}"> Labs </router-link>
					</li>
					<li class="nav-item">
						<router-link class="nav-link" target="_blank" :to="{ name: 'AllImagingList'}"> Imaging </router-link>
					</li>
					<li class="nav-item">
						<router-link class="nav-link" target="_blank" :to="{ name: 'EChartFilesOverview'}"> Files </router-link>
					</li>
					<li class="nav-item">
						<router-link class="nav-link" target="_blank" :to="{ name: 'templateMasterList'}"> Templates</router-link>
					</li>
					<li class="nav-item">
						<router-link class="nav-link" target="_blank" :to="{ name: 'AccountSettings'}"> Administration</router-link>
					</li>
				</ul>

				<ul class="navbar-nav ms-auto contact_detail">
					<li class="nav-item"><a class="nav-link" href="#" @click="openContactUsModels"> Contact Us </a></li>
					<li class="nav-item"><a class="nav-link" href="#" @click="logout"> Log Out </a></li>
					<!-- <li class="nav-item dropdown profile_box">
						<a class="nav-link  dropdown-toggle me-0" href="#" data-bs-toggle="dropdown"> Admin </a>
						<ul class="dropdown-menu dropdown-menu-right">
							<li><a class="dropdown-item" href="#"><i class="fa-solid fa-user"></i> Profile</a></li>
							<li><a class="dropdown-item" href="#" @click="logout"><i
										class="fa-solid fa-right-from-bracket"></i> Log Out </a></li>
						</ul>
					</li> -->
				</ul>

			</div> <!-- navbar-collapse.// -->
		</div> <!-- container-fluid.// -->
	</nav>
</template>
<script>
import axios from "@/scripts/axios.js";
export default {
	emits: ['open-contact-modal'],
	components: {},
	data() {
		return {
			inboxCount:0,
			taskCount:0
		};
	},
	validations() { },
	created() { },
	mounted() {
		document.addEventListener("DOMContentLoaded", function () {
			document.querySelectorAll('.dropdown-menu').forEach(function (element) {
				element.addEventListener('click', function (e) {
					e.stopPropagation();
				});
			});
			// make it as accordion for smaller screens
			if (window.innerWidth < 992) {
				// close all inner dropdowns when parent is closed
				document.querySelectorAll('.navbar .dropdown').forEach(function (everydropdown) {
					everydropdown.addEventListener('hidden.bs.dropdown', function () {
						// after dropdown is hidden, then find all submenus
						this.querySelectorAll('.submenu').forEach(function (everysubmenu) {
							// hide every submenu as well
							everysubmenu.style.display = 'none';
						});
					})
				});

				document.querySelectorAll('.dropdown-menu a').forEach(function (element) {
					element.addEventListener('click', function (e) {

						let nextEl = this.nextElementSibling;
						if (nextEl && nextEl.classList.contains('submenu')) {
							// prevent opening link if link needs to open dropdown
							e.preventDefault();
							console.log(nextEl);
							if (nextEl.style.display == 'block') {
								nextEl.style.display = 'none';
							} else {
								nextEl.style.display = 'block';
							}
						}
					});
				})
			}
			// end if innerWidth

		});
		// DOMContentLoaded  end
		this.getInboxCount()
		this.gettaskCount()
	},
	methods: {
		openContactUsModels() {
			this.$emit('open-contact-modal');
		},
		logout() {
			axios.post("auth/logout").then(() => {
				localStorage.clear();
				setTimeout(function () {
					location.reload();
				}, 100);
			});
		},
		getInboxCount(){
			let url = 'inbox/count'
			axios.post(url, {})
				.then((response) => {
					if (response.status == 200) {
						this.inboxCount = response.data.data.count
					}
				})
		},
		gettaskCount(){
			let url = 'patient/task/count'
			axios.post(url, {})
				.then((response) => {
					if (response.status == 200) {
						this.taskCount = response.data.data.count
					}
				})

		}
	},
	computed: {

	},
	
};

</script>