<template>
	<div class="">
		<Header @open-contact-modal="openContactUsModel"></Header>
	</div>
	<div class="content">
		<div class="container-fluid">
			<section class="content">
				<component :is="$store.state.component" ref="dynamicComponent">

				</component>
			</section>
		</div>
	</div>
</template>
<script>
import Calendar from '../../components/calendar/Calendar.vue';
// import CalendarV2 from '../../components/calendar/CalendarV2.vue';
import Header from '../Header.vue';
export default {
	components: {
		Header,
		Calendar,
		// CalendarV2
	},
	created() {
		this.$store.state.component = this.$route.name;
	},
	methods: {
		openContactUsModel() {
			// Check if the dynamic component (e.g., Calendar.vue) has the method and call it
			const dynamicComponent = this.$refs.dynamicComponent;
			if (dynamicComponent && dynamicComponent.openContactUsModel) {
				dynamicComponent.openContactUsModel();
			} else {
				console.error('The dynamic component does not have an openContactUsModel method.');
			}
		},
	},
}
</script>